import React from 'react'
import { Router } from '@reach/router'
import useSiteMetadata from 'hooks/useSiteMetadata'
import { getCookie, setCookie } from 'utils/cookies'
import isSearchBot from 'utils/isSearchBot'
import PageNotFound from '../pages/404'

const redirect = url => {
	window.location.href = url
	return null
}

// Адрес без query-переменной r
const withoutRef = url => url.replace(/\/ref\/\d{1,}/, '')

const Partner = props => {
	const { partnerApiUrl } = useSiteMetadata()
	const { partnerAi } = props
	const target = '/' + props['*']

	// Номер партнера из кук
	const parentAiFromCookie = getCookie('partnerAi', 'number')

	if (!partnerAi) return redirect('/')
	if (isSearchBot()) return redirect('/')
	if (parentAiFromCookie) return redirect(target)

	fetch(`${partnerApiUrl}/referral`, {
		method: 'POST',
		headers: { 'Content-Type': 'application/json;charset=utf-8' },
		body: JSON.stringify({
			partnerAi,
			type: 'direct',
			data: {
				fromUrl: document.referrer,
				toUrl: withoutRef(window.location.href),
			},
		}),
	})
		.then(async response => {
			// Все проверки на сервере прошли успешно => парсим ответ
			if (response.ok) return await response.json()
		})
		.then(res => {
			if (res) {
				setCookie('partnerAi', res.partnerAi)
				setCookie('referralAi', res.referralAi)
			}
			redirect(target)
		})

	return null
}

const RefPage = () => (
	<Router>
		<Partner path="/ref/:partnerAi/*" />
		<PageNotFound path="/ref/*" />
	</Router>
)

export default RefPage
