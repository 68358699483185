import { getUA } from 'react-device-detect'

/**
 * Является ли текущий User Agent поисковым роботом
 * @returns {boolean}
 */
const isSearchBot = () =>
	/(prerender|googlebot|bingbot|yandex|baiduspider|twitterbot|facebookexternalhit|rogerbot|linkedinbot|embedly|quora link preview|showyoubot|outbrain|pinterest|slackbot|vkShare|W3C_Validator|validator|lighthouse|facebook|Google-Structured|YandexPagechecker|Google-Structured-Data-Testing-Tool)/i.test(
		getUA
	)

export default isSearchBot
